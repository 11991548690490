import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Features from '../components/Features';
import BlogRoll from '../components/BlogRoll';
import { PageTemplateType, PageTemplateDefaultProps } from '../types/page-template-type';
import { PageType, PageTypeDefaultProps } from '../types/page-type';

const Title = ({ title = null }) => (title ? (
  <h1
    className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
    style={{
      boxShadow: 'rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px',
      backgroundColor: 'rgb(255, 68, 0)',
      color: 'white',
      lineHeight: '1',
      padding: '0.25em',
    }}
  >
    {title}
  </h1>
) : (
  <></>
));

Title.propTypes = {
  title: PropTypes.string,
};

Title.defaultProps = {
  title: null,
};

const Subheading = ({ subheading = null }) => (subheading ? (
  <h3
    className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
    style={{
      boxShadow: 'rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px',
      backgroundColor: 'rgb(255, 68, 0)',
      color: 'white',
      lineHeight: '1',
      padding: '0.25em',
    }}
  >
    {subheading}
  </h3>
) : (
  <></>
));

Subheading.propTypes = {
  subheading: PropTypes.string,
};

Subheading.defaultProps = {
  subheading: null,
};

const MainPitch = ({ mainpitch }) => (
  mainpitch
    ? (
      <div className="content">
        <div className="tile">
          <h1 className="title">{mainpitch.title}</h1>
        </div>
        <div className="tile">
          <h3 className="subtitle">{mainpitch.description}</h3>
        </div>
      </div>
    )
    : <></>
);

MainPitch.propTypes = {
  mainpitch: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

MainPitch.defaultProps = {
  mainpitch: null,
};

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${image.childImageSharp ? image.childImageSharp.fluid.src : image})`,
        backgroundPosition: 'top left',
        backgroundAttachment: 'fixed',
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <Title title={title} />
        <Subheading subheading={subheading} />
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <MainPitch mainpitch={mainpitch} />
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
                    <p>{description}</p>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">Latest stories</h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  ...PageTemplateType,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

IndexPageTemplate.defaultProps = {
  ...PageTemplateDefaultProps,
  image: null,
  heading: null,
  subheading: null,
  intro: [],
  mainpitch: null,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = PageType;
IndexPage.defaultProps = PageTypeDefaultProps;

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        intro {
          heading
          description
        }
      }
    }
  }
`;
